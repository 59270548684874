import { createSlice } from '@reduxjs/toolkit';
import { ChangePass, confirmPass, SendEmail, userLogin, VerifyToken, getUserProfile, changeDefaultPass, confirmPassword } from 'services/actions/authAction';

interface AuthState {
    loading: boolean;
    userInfo: any; // Define the type of userInfo
    error: any; // Define the type of error
    success: boolean;
    //confirm email
    loading1: boolean;
    resetInfo: any;
    error1: any;
    success1: boolean;
    loading2: boolean;
    passInfo: any;
    error2: any;
    success2: boolean;
    //verify token
    loading3: boolean;
    tokenInfo: any;
    error3: any;
    success3: boolean;
    //reset pass
    loading4: boolean;
    resetpassInfo: any;
    error4: any;
    success4: boolean;
    //change password
    loading5: boolean;
    defaultPassInfo: any;
    error5: any;
    success5: boolean;
    // user profile
    loading6: boolean;
    userProfileInfo: any;
    error6: any;
    success6: boolean;
    //confirm passowrd
    loading7: boolean;
    conPass: any;
    error7: any;
    success7: boolean;
}
const initialState: AuthState = {
    loading: false,
    userInfo: null,
    error: null,
    success: false,
    //confirm email
    loading1: false,
    resetInfo: null,
    error1: null,
    success1: false,
    loading2: false,
    passInfo: null,
    error2: null,
    success2: false,
    //verify token
    loading3: false,
    tokenInfo: null,
    error3: null,
    success3: false,
    //reset pass
    loading4: false,
    resetpassInfo: null,
    error4: null,
    success4: false,
    //changepass
    loading5: false,
    defaultPassInfo: null,
    error5: null,
    success5: false,
    //user profile 
    loading6: false,
    userProfileInfo: null,
    error6: null,
    success6: false,    
    // comfirm passowrd
    loading7: false,
    conPass:null,
    error7: null,
    success7: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.success = true;
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //resetpassword
            .addCase(SendEmail.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(SendEmail.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.resetInfo = payload;
                state.success1 = true;
            })
            .addCase(SendEmail.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //changePassword
            .addCase(ChangePass.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(ChangePass.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.passInfo = payload;
                state.success2 = true;
            })
            .addCase(ChangePass.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //reset-pass
            .addCase(confirmPass.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(confirmPass.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.tokenInfo = payload;
                state.success3 = true;
            })
            .addCase(confirmPass.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //verify token
            .addCase(VerifyToken.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(VerifyToken.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.tokenInfo = payload;
                state.success4 = true;
            })
            .addCase(VerifyToken.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            //change default password
            .addCase(changeDefaultPass.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(changeDefaultPass.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.defaultPassInfo = payload;
                state.success5 = true;
            })
            .addCase(changeDefaultPass.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
            // user profile 
            .addCase(getUserProfile.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getUserProfile.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.userProfileInfo = payload;
                state.success6 = true;
            })
            .addCase(getUserProfile.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            })
            //conform passowrd
            .addCase(confirmPassword.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(confirmPassword.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.conPass = payload;
                state.success7 = true;
            })
            .addCase(confirmPassword.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })

    },
});

export default authSlice.reducer;
