import React, { useState, useEffect, lazy, Suspense } from 'react';
import loadable from '@loadable/component';
import AccountDetail from 'components/account/AccountDetail';
import AccountManagement from 'components/account/AccountManagement';
// import CreditManager from 'components/billing-and-invoice/CreditManager';
// import InvoiceManager from 'components/billing-and-invoice/InvoiceManager';
// import GenerateInvoice from 'components/billing-and-invoice/sub-screens/additional-screen/GenerateInvoice';
// import AdminDashboard from 'components/dashboard/AdminDashboard';
import JobManager from 'components/job-manager/JobManager';
import EdfProcessing from 'components/job-manager/sub-screens/EdfProcessing';
import LayoutPage from 'components/layout/LayoutPage';

import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
// import InvoiceTemplate from 'components/billing-and-invoice/sub-screens/additional-screen/InvoiceTemplate';
// import PipelineWizard from 'components/request/pipeline-request/sub-screens/PipelineWizard';
import PaymentSuccess from 'components/billing-and-invoice/payment-status/PaymentSuccess';
import PaymentFailure from 'components/billing-and-invoice/payment-status/PaymentFailure';

import CrPaymentSuccess from 'components/billing-and-invoice/payment-status/CrPaymentSuccess';
import CrPaymentFailure from 'components/billing-and-invoice/payment-status/CrPaymentFailure';
// import ReportRate from 'components/request/modal/ReportRate';
import AutopaySuccess from 'components/account/modal/AutopaySuccess';
import AutopayError from 'components/account/modal/AutopauError';
import ArtifactToggle from 'components/job-manager/ArtifactToggle';
import UnAuthorized from 'components/shared/UnAuthorizedPage';
import { useSelector } from 'react-redux';
import OopsPage from 'components/shared/OopsPage';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingIndicator from 'components/shared/LoadingIndicator';
// import MasterRoute from 'components/routes/MasterRoute';

// import Amplifier from 'components/master-data/Amplifier';
// import Lifestyle from 'components/master-data/Lifestyle';
// import MarkerManagement from 'components/master-data/MarkerManagement';
// import RecommendedMedic from 'components/master-data/RecommendedMedic';
// import Supplement from 'components/master-data/Supplement';
// import Symptoms from 'components/master-data/Symptoms';
// import ReportComparison from 'components/report-comparison/ReportComparison';
// import Patient from 'components/patient/Patient';
// import PatientDetail from 'components/patient/PatientDetail';
// import ArchivedRequest from 'components/request/ArchivedRequest';
// import OrderManagement from 'components/request/modal/OrderManagement';
// import NewRequest from 'components/request/new-request/NewRequest';
// import PipelineRequest from 'components/request/pipeline-request/PipelineRequest';
// import ReleasedRequest from 'components/request/released-request/ReleasedRequest';
// import DatasetInformation from 'components/request/released-request/sub-screens/DatasetInformation';
// import GlobalSearchResult from 'components/search/GlobalSearchResult';
// import UserAccountManagement from 'components/security/Security';
// import EDFSetting from 'components/settings/edf-setting/EDFSetting';
// import AmazonSearch from 'components/search/AmazonSearch';
// import MyCredit from 'components/billing-and-invoice/account/MyCredit';
// import BuyCredit from 'components/billing-and-invoice/account/BuyCredit';

const AdminDashboard = loadable(()=>import('components/dashboard/AdminDashboard'));

const ReportComparison = loadable(() => import('components/report-comparison/ReportComparison'));
const Patient = loadable(() => import('components/patient/Patient'));
const PatientDetail = loadable(() => import('components/patient/PatientDetail'));

const GlobalSearchResult = loadable(() => import('components/search/GlobalSearchResult'));
const UserAccountManagement = loadable(() => import('components/security/Security'));
const EDFSetting = loadable(() => import('components/settings/edf-setting/EDFSetting'));
const AmazonSearch = loadable(() => import('components/search/AmazonSearch'));

const MasterRoute = loadable(()=>import('components/routes/MasterRoute'));
// const RequestRoute = loadable(()=>('components/routes/RequestRoute'));


const extractMenuUrls = (menuData) => {
    const urls: string[] = [
        'search',
        'security',
        'report-comparison',
        'order-management',
        'generate-invoice',
        'invoice1',
        'report-rate',
        'invsuccess-payment',
        'inverror-payment',
        'error-payment',
        'customer-agreement',
        'success-payment',
        'payment-detail',
        'buy-credit',
        'medicine-automation',
        'supplement-automation',
        'lifestyle-automation',
        'research/recommended-medication',
        'amplifier-master',
        'autopay-success',
        'autopay-error',
        'credit-payment-success',
        'credit-payment-error'
    ];

    if (Array.isArray(menuData)) {
        menuData.forEach((item: any) => {
            if (item.menu_url) urls.push(item.menu_url);
            if (item.submenuservice && Array.isArray(item.submenuservice)) {
                item.submenuservice.forEach((subItem: any) => {
                    if (subItem.menu_path) urls.push(subItem.menu_path);
                });
            }
        });
    }
    return urls.filter((url) => url);
};

const normalizePath = (path) => {
    return path.replace(/^\//, '').toLowerCase().split(/[?#]/)[0];
};

const isPathAllowed = (currentPath, allowedPaths) => {
    const normalizedCurrentPath = normalizePath(currentPath);
    return allowedPaths.some((path) => {
        const normalizedAllowedPath = normalizePath(path);
        return normalizedCurrentPath === normalizedAllowedPath || normalizedCurrentPath.startsWith(`${normalizedAllowedPath}/`);
    });
};

const PrivateRoute: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { menuInfo, loading1 } = useSelector((state: any) => state.dashboard);
    const [allowedPaths, setAllowedPaths]: any = useState([]);

    useEffect(() => {
        if (!loading1 && menuInfo?.data) {
            const paths = extractMenuUrls(menuInfo?.data);
            setAllowedPaths(paths);
        }
    }, [menuInfo, loading1]);


    useEffect(() => {
        if (loading1 || allowedPaths.length === 0) {
            return;
        }
        const currentPath = normalizePath(location?.pathname);
        const pathIsAllowed = isPathAllowed(currentPath, allowedPaths);

        if (!pathIsAllowed) {
            navigate('/unauthorized');
        }
    }, [location.pathname, allowedPaths, loading1, navigate]);

    return (
        <div>
            {location.pathname !== '/unauthorized' && (
                <ErrorBoundary FallbackComponent={OopsPage}>
                    <LayoutPage>
                        {/* <Suspense fallback={<LoadingIndicator />}> */}
                            <Routes>
                                <Route path="/dashboard" element={<AdminDashboard />} />
                                {/* <Route path="/new-request" element={<NewRequest />} /> */}
                                <Route path="/search" element={<GlobalSearchResult />} />
                                {/* <Route path="/view-request" element={<PipelineRequest />} />
                                <Route path="/released-request" element={<ReleasedRequest />} />
                                <Route path="/archive-list" element={<ArchivedRequest />} /> */}
                                <Route path="/patient-list" element={<Patient />} />
                                <Route path="/account" element={<AccountManagement />} />
                                
                                {/* <Route path="/diagnosis" element={<Diagnosis />} />
                                <Route path="/amplifier-master" element={<Amplifier />} />
                                <Route path="/lifestyle" element={<Lifestyle />} />
                                <Route path="/marker-management" element={<MarkerManagement />} />
                                <Route path="/recommended-medication" element={<RecommendedMedic />} />
                                <Route path="/supplement" element={<Supplement />} />
                                <Route path="/symptoms" element={<Symptoms />} /> */}
                                <Route path="/patient-list/patient-medication" element={<PatientDetail />} />
                                <Route path="/account/account-details" element={<AccountDetail />} />
                                <Route path="/edf-config" element={<EDFSetting />} />
                                {/* <Route path="/credit-manager" element={<CreditManager />} />
                                <Route path="/invoice-manager" element={<InvoiceManager />} />
                                <Route path="/invoice-manager/generate-invoice" element={<GenerateInvoice />} /> */}
                                <Route path="/edf_job_manager" element={<JobManager />} />
                                <Route path="/edf_job_manager/edf-processing" element={<EdfProcessing />} />
                                {/* <Route path="/new-request/order-management" element={<OrderManagement />} />
                                <Route path="/view-request/order-management" element={<OrderManagement />} /> */}
                                <Route path="/report-comparison" element={<ReportComparison />} />
                                <Route path="/security" element={<UserAccountManagement />} />
                                {/* <Route path="/released-request/dataset-information" element={<DatasetInformation />} /> */}
                                {/* <Route path="/invoice-manager/invoice" element={<InvoiceTemplate />} /> */}
                                {/* <Route path="/view-request/pipeline-request" element={<PipelineWizard />} /> */}
                                <Route path="/invsuccess-payment" element={<PaymentSuccess />} />
                                <Route path="/inverror-payment" element={<PaymentFailure />} />
                                <Route path="/amazon-search" element={<AmazonSearch />} />
                                {/* <Route path="/my-credit" element={<MyCredit />} />
                                <Route path="/buy-credit" element={<BuyCredit edit={false} closeModal={() => { }} />} /> */}
                                <Route path="/credit-payment-success" element={<CrPaymentSuccess />} />
                                <Route path="/credit-payment-error" element={<CrPaymentFailure />} />
                                <Route path="/autopay-success" element={<AutopaySuccess />} />
                                <Route path="/autopay-error" element={<AutopayError />} />
                                {/* <Route path="/report-rate" element={<ReportRate data />} /> */}
                                <Route path="/edf_job_manager/edf-analyzer" element={<ArtifactToggle />} />
                                <Route path="*" element={<MasterRoute />} />
                               
                                 
                            </Routes>
                            
                        {/* </Suspense> */}
                    </LayoutPage>
                </ErrorBoundary>
            )}
                           
            <Routes>
                <Route path="/unauthorized" element={<UnAuthorized />} />

            </Routes>
        </div>
    );
};

export default PrivateRoute;
