import React, { useState, useEffect, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './components/auth/LoginPage';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/ResetPassword';
import PrivateRoute from 'components/routes/PrivateRoute';
import EdfViewer from 'components/edfViewer/EdfViewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/app.scss';
import 'assets/styles/customtheme.scss';
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ChangePassword from 'components/auth/ChangePassword';
const EmailSentForm = lazy(() => import('components/auth/EmailSentForm'));
const Page404 = lazy(() => import('components/auth/Page404'));
const PatientIntakeForm = lazy(() => import('components/PatientIntakeForm'));

function App() {
    const isAuth = localStorage.getItem('token') === "true"
    const { userInfo } = useSelector((state: any) => state.auth);
    const hasUserInfo = userInfo && Object.keys(userInfo).length > 0;
    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/reset-password" element={<ForgotPassword />}></Route>
                <Route path="/email-sent" element={<EmailSentForm />}></Route>
                <Route path="/change-password/:id" element={<ChangePassword />} />
                <Route path="/page404" element={<Page404 />}></Route>
                <Route path="/patient-form/:id" element={<PatientIntakeForm />}></Route>
                <Route path="/change-default-password" element={<ResetPassword />}></Route>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/edf" element={<EdfViewer />}></Route>
                {isAuth || hasUserInfo ? <Route path="*" element={<PrivateRoute />} /> : <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
        </div>
    );
}

export default App;
