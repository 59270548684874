import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'services/apiService';
import config from 'assets/config';

const base_url = config.apiURL;

export const getUserRoles = createAsyncThunk('get-user-roles', async () => {
    try {
        const { data } = await ax.post(`${base_url}account/getusergroups`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});

export const getAllUsers = createAsyncThunk('get-all-userss', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/getalluser`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const saveUser = createAsyncThunk('add-all-user', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/generate_user`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
