import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'services/apiService';
import config from 'assets/config';

const base_url = config.apiURL;

// account/dashboard
export const DashboardDetail = createAsyncThunk('account-dashboard', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/dashboard`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// auth/menuservice
export const menuDetails = createAsyncThunk('menu-details', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/menuservice`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const getNotification = createAsyncThunk('get-notification', async () => {
    try {
        const { data } = await ax.get(`${base_url}datahub_report/get_notification`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});

export const getClearNotification = createAsyncThunk('get-clear-notification', async (payload:any) => {
    try {
        const { data } = await ax.post(`${base_url}datahub_report/getclear_notification`, payload);
        return data;
    } catch (error:any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.message;
        }
    }
});
