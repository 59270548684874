import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'services/apiService';
import config from 'assets/config';

const base_url = config.apiURL;

export const userLogin = createAsyncThunk('user-login', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/login`, payload);
        localStorage.setItem('token', true.toString());
        localStorage.setItem('userid', data.data.userid);
        sessionStorage.setItem('userid', data.data.userid);
        sessionStorage.setItem('role', data.data.role);
        sessionStorage.setItem('accountid', data.data.user_acctid);
        sessionStorage.setItem('firstname', data.data.firstname);
        sessionStorage.setItem('lastname', data.data.lastname);
        sessionStorage.setItem('username', data.data.username);
        sessionStorage.setItem('password', data.data.password);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//auth/password_reset
export const SendEmail = createAsyncThunk('auth-send-email', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset`, payload);
        // store user's token in local storage
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//change password
export const ChangePass = createAsyncThunk('auth-change-pass', async (payload: any, { rejectWithValue }) => {
    try {
        // configure header's Content-Type as JSON
        const { data } = await ax.put(`${base_url}auth/change-password`, payload);
        // store user's token in local storage
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//confirm reset password
export const confirmPass = createAsyncThunk('auth/confirm-pass', async (payload:any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset/confirm/`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//verify token
export const VerifyToken = createAsyncThunk('auth/verify-token', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/reset-password/verify-token`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//user profilecall
export const getUserProfile = createAsyncThunk('account-user-profile', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}account/UserProfileCall`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const changeDefaultPass = createAsyncThunk('default-pass-user-profile', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/login_auth`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const confirmPassword = createAsyncThunk('auth/confirm-password', async (payload:any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}auth/password_reset/password_reset_confirm`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});
